import React, { Component } from "react";

import { Formik, Field, Form } from 'formik';

import PhotoAlbum from "react-photo-album";
import sanitize from "sanitize-filename";

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

// import ZoomedImage from "./ZoomedImage"

// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
// import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
// import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
// import Captions from "yet-another-react-lightbox/plugins/captions";
// import Zoom from "yet-another-react-lightbox/plugins/zoom";
// import "yet-another-react-lightbox/plugins/thumbnails.css";
// import "yet-another-react-lightbox/plugins/captions.css";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategories: [],
            prevRequest: 0,
            requestUrl: '',
            results: '',
            index: -1
        }
    }

    componentDidMount() {
        let serverUrl = 'http://localhost:8080/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:8080/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl }, this.getData)
    }

    setIndex(value) {
        this.setState({ index: value });
    }


    getData() {
        this.getArtists();
        this.getLocations();
        this.getTags();
        this.requestApi();
    }
    getArtists() {
        // const params = new URLSearchParams({})
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'artists', options)
            .then((response) => response.json())
            .then((json) => {
                // let artists = [];
                // for (let artist in json) {
                //     if (json[artist].fullname != '') {
                //         artists.push(json[artist].artist)
                //     }
                // }
                this.setState({ artists: json })
            })
            .catch(err => console.log(err));
    }
    getLocations() {
        // const params = new URLSearchParams({})
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'location', options)
            .then((response) => response.json())
            .then((json) => {
                let locations = [];
                for (let location in json) {
                    if (json[location].location != '') {
                        locations.push(json[location].location)
                    }
                }
                this.setState({ locations: locations })
            })
            .catch(err => console.log(err));
    }
    getTags() {
        // const params = new URLSearchParams({})
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'tags', options)
            .then((response) => response.json())
            .then((json) => {
                let tags = {};
                for (let tag in json) {
                    if (json[tag].tag != '') {
                        tags[json[tag].tag] = json[tag].id
                    }
                }
                this.setState({ tags: tags })
            })
            .catch(err => console.log(err));
    }

    // addCategory() {
    //     const category = document.getElementById('category').value;

    //     let categoryExist = false;
    //     let categoryAlreadySelected = false;


    //     for (let selCat of this.state.selectedCategories) {
    //         if (selCat.name === category) {
    //             categoryAlreadySelected = true;
    //             document.getElementById('category').value = null;
    //         }
    //     }

    //     if (!categoryAlreadySelected) {
    //         for (let cat of this.state.categories) {
    //             if (category === cat.name) {
    //                 categoryExist = true;
    //                 this.setState({ selectedCategories: [...this.state.selectedCategories, { id: cat.id, name: cat.name }] })
    //                 document.getElementById('category').value = null;
    //                 break;
    //             }
    //         }
    //         if (!categoryExist) {
    //             // Add a new category
    //             return;
    //         }
    //     }
    // }

    // deleteCategory(id) {
    //     let newSelectedCategories = this.state.selectedCategories;

    //     newSelectedCategories = newSelectedCategories.filter(function (value, index, arr) {
    //         return value.id !== id;
    //     });

    //     this.setState({ selectedCategories: newSelectedCategories })
    // }

    requestApi(values) {
        if (this.state.prevRequest > Date.now() - 200) return;
        this.setState({ prevRequest: Date.now() })

        let urlSearchParams = {};

        if (values) {
            // Keywords
            if (values.keywords) {
                const keywords = values.keywords.replace(" ", ",");
                if (keywords !== '') urlSearchParams.k = keywords;
            }
            // Shuffle
            urlSearchParams.o = values.order;
        }

        const params = new URLSearchParams(urlSearchParams)
        const options = {
            method: 'GET'
        }
        const requestUrl = this.state.serverUrl + 'paintings?' + params;
        this.setState({ requestUrl: requestUrl });
        fetch(requestUrl, options)
            .then((response) => response.json()
            )
            .then((json) => {
                this.setState({ results: json })
                console.log(json)

                /////////////////
                // let artistCount = 0;
                // let artistList = this.state.artists;

                // console.log(artistList)
                // console.log(artistList[0].fullname)

                // console.log(this.state.tags)
                // for (let artist of artistList) {

                //     // let prenom = artist.split(' ').slice(0, -1).join(' ').trim();
                //     // let nom = artist.split(' ').slice(-1).join(' ').trim();

                //     // console.log(artist + ' : ' + prenom + ', ' + nom);
                //     const values = {
                //         surname: prenom,
                //         name: nom,
                //         fullName: artist
                //         // paintingID: paintingID
                //     }
                //     const options = {
                //         method: 'POST',
                //         headers: {
                //             'Content-Type': 'application/json'
                //         },
                //         body: JSON.stringify(values)
                //     }
                //     fetch(this.state.serverUrl + 'addArtist', options)
                //         .then(res => res.json().then(json => {
                //             if (json.message === 'success') {
                //                 console.log('success' + json.id)
                //             }
                //         }));
                // }
                // for (let painting of json) {
                //     const paintingID = painting.id;
                //     let artist = painting.artist
                //     let artistID = 0;

                //     for (let existingArtist of this.state.artists) {
                //         if (existingArtist.fullname == artist) {
                //             artistID = existingArtist.id
                //         }
                //     }
                //     console.log(paintingID, artist, artistID)

                //     const values = {
                //         artistID: artistID,
                //         paintingID: paintingID
                //     }
                //     const options = {
                //         method: 'POST',
                //         headers: {
                //             'Content-Type': 'application/json'
                //         },
                //         body: JSON.stringify(values)
                //     }

                //     if (artistID == null) {
                //         console.log(values)
                //     }
                //     // fetch(this.state.serverUrl + 'artistRelation', options)
                //     //     .then(res => res.json().then(json => {
                //     //         if (json.message === 'success') {
                //     //             console.log('success' + json.id)
                //     //         }
                //     //     }));

                // }

                // console.log(tagList)
                // console.log('tagCount', tagCount)

                ////////////////////
            })
            .catch(err => console.log(err));
    }

    render() {
        let results = [];
        if (this.state.results !== '') results = this.state.results;

        let photos = []
        if (results.length > 0) {
            for (let result of results) {
                const artist = result.artist ? result.artist + '__' : 'Anonymous__';
                const year = result.year ? '__' + result.year : '';
                const fileName = sanitize(artist + result.title + year);

                photos.push({
                    src: this.state.serverUrl + 'small/' + fileName + '.jpg',
                    href: '/painting/' + result.id,
                    width: result.width,
                    height: result.height,
                    description: result.artist + ' - ' + result.title + ' (' + result.year + ')',
                })
            }
        }



        return (
            <>
                <section className="hero is-primary">
                    <div className="hero-body">
                        <Formik
                            initialValues={{
                                keywords: '',
                                shuffle: false,
                                order: 0
                            }}
                            onSubmit={async (values, actions) => {
                                this.requestApi(values, actions);
                            }}>
                            {({ errors, touched, values, setFieldValue }) => (
                                <Form className="questionForm container" encType="multipart/form-data">
                                    {/* KEYWORDS */}
                                    <div className="field">
                                        <label htmlFor="keywords" className="label has-text-white">Recherche </label>
                                        <div className="control has-icons-left">
                                            <Field id="keywords" name="keywords" placeholder="Keywords" className={errors.keywords && touched.keywords ? 'input is-danger' : 'input'} autoFocus min="1" />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-question"></i>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className="field">
                                        <label className="checkbox has-text-white">
                                            <Field type="checkbox" name="shuffle" />
                                            <span className="ml-1">Aléatoire</span>
                                        </label>
                                    </div> */}
                                    <div className="field">
                                        <label className="checkbox has-text-white">Trier par</label><br />
                                        <div class="control has-icons-left">
                                            <div class="select">
                                                <Field name="order" as="select">
                                                    <option value="0">Aléatoire</option>
                                                    <option value="1">Artiste</option>
                                                    <option value="2">Date</option>
                                                    <option value="3">Titre</option>
                                                    <option value="4">ID</option>
                                                </Field>
                                            </div>
                                            <div class="icon is-small is-left">
                                                <i class="mdi mdi-swap-vertical mdi-24px"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="button is-primary is-light">Submit</button>
                                </Form>

                            )

                            }
                        </Formik>

                    </div></section >
                {/* <PhotoAlbum layout="masonry" spacing={0} photos={photos} columns={3} /> */}

                < PhotoAlbum
                    renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                        // <a href={photo.href} style={wrapperStyle}>
                        //     {renderDefaultPhoto({ wrapped: true })}
                        // </a>
                        // <Zoom >
                        <img
                            alt={photo.description}
                            title={photo.description}
                            src={photo.src}
                            loading="lazy"
                        />
                        // </Zoom>

                    )
                    }
                    layout="masonry" spacing={0} photos={photos} columns={3}
                />

                {/*  onClick={({ index }) => this.setIndex(index)} */}

                {/* <Lightbox
                    slides={photos}
                    open={this.state.index >= 0}
                    index={this.state.index}
                    close={() => this.setIndex(-1)}
                    slideshow={{ delay: 5000 }}
                    captions={{ showToggle: false, descriptionTextAlign: 'end', descriptionMaxLines: 2 }}
                    // enable optional lightbox plugins
                    plugins={[Fullscreen, Slideshow, Zoom, Captions]}
                /> */}
            </>
        )
    }
}




export default App;
